<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-dialog v-model="dialog" width="800" persistent fullscreen transition="dialog-bottom-transition">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class=" blue--text mb-2 accent-2" outlined v-bind="attrs" v-on="on"
                            @click="getCustomersReference(); getItemsReference()"><v-icon>mdi-plus</v-icon> زیادکردنی
                            فرۆشتن</v-btn>
                    </template>
                    <v-card>
                        <v-toolbar dark outlined :class="isUpdate ? 'orange accent-2' : 'blue accent-2'" elevation="1">
                            <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn :loading="addLoading" text @click="isUpdate ? update() : create()">
                                    <v-icon>{{ isUpdate ? 'mdi-check' : 'mdi-plus' }}</v-icon> {{ isUpdate ? 'نوێکردنەوەی
                                                                        فرۆشتن' :
                                    'زیادکردنی فرۆشتن' }} </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-form ref="sale">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" md="2" lg="2" sm="12">
                                        <v-autocomplete :items="customerReference" item-text="customer_name"
                                            item-value="customer_id" dense outlined label="ناوی کڕیار"
                                            v-model="sale.customer_id" :rules="rules.customerRule"></v-autocomplete>
                                        <v-select :items="[{ text: 'کاش', value: 'c' }, { text: 'قەرز', value: 'd' }]"
                                            item-text="text" item-value="value" dense label="پارەدان" outlined
                                            v-model="sale.payment_type"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="2" lg="2" sm="12">
                                        <v-select :items="[{ text: 'جوملە', value: 1 }, { text: 'مفرد', value: 0 }]"
                                            item-text="text" item-value="value" dense label="جملە/مفرد" outlined
                                            v-model="sale.is_wholesale"></v-select>
                                        <v-select
                                            :items="[{ text: 'فرۆشتن', value: 's' }, { text: 'گەڕانەوە', value: 'rs' }]"
                                            item-text="text" item-value="value" dense label="جۆری وەسڵ" outlined
                                            v-model="sale.sale_type"></v-select>
                                    </v-col>
                                    <v-col cols="12" md="1" lg="1" sm="12">
                                        <v-text-field @focus="$event.target.select()" dense label="داشکاندن" outlined
                                            v-model="sale.discount" type="number"></v-text-field>
                                        <v-text-field @focus="$event.target.select()" dense label="ژمارەی وەصل" outlined
                                            v-model="sale.no" type="number"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="2" lg="2" sm="12">
                                        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field @focus="$event.target.select()" v-model="sale.date"
                                                    label="بەروار" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                                                    v-on="on" outlined dense></v-text-field>
                                            </template>
                                            <v-date-picker v-model="sale.date" @input="menu1 = false"></v-date-picker>
                                        </v-menu>
                                        <v-text-field @focus="$event.target.select()" dense label="بڕی واصل کراو" outlined
                                            v-model="sale.paid" type="number"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="3" lg="3" sm="12">

                                        <v-textarea label="تێبینی" rows="1" auto-grow outlined dense
                                            v-model="sale.note"></v-textarea>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="12" md="2" lg="2" sm="12">
                                        <v-text-field @focus="$event.target.select()" success id="success" dense readonly
                                            label="سەرجەم" outlined :value="'$' + total"></v-text-field>
                                    </v-col>
                                </v-row>
                                <hr>
                                <br>
                                <v-row>
                                    <v-col>
                                        <v-data-table class="elevation-2 rounded-lg" :item-class="itemRowBackground"
                                            :headers="secondHeaders" :items="sale.sale_items" hide-default-footer
                                            :items-per-page="-1">

                                            <template v-slot:[`item.total`]="{ item }">
                                                ${{ ((Number(sale.is_wholesale == 1 ? item.item_price_wholesale :
                                                    item.item_price) * item.qty_item) + (Number(sale.is_wholesale == 1 ?
                                                        item.item_price_wholesale : item.item_price) * item.qty_unit *
                                                        item.item_per_unit)).toFixed(2) }}
                                            </template>
                                            <template v-slot:[`item.index`]="{ item }">
                                                {{ sale.sale_items.indexOf(item) + 1 }}
                                            </template>
                                            <template v-slot:[`item.total_item`]="{ item }">
                                                {{ Number(item.item_per_unit * item.qty_unit) + Number(item.qty_item) }}
                                            </template>
                                            <template v-slot:[`item.item_name`]="item">
                                                <v-edit-dialog>
                                                    {{ item.item.item_name }}
                                                    <template v-slot:input>

                                                        <v-autocomplete :disabled="item.item.delete" class="my-5"
                                                            :items="itemReference" label="ناوی کاڵا" outlined dense
                                                            item-text="item_name" item-value="item_id"
                                                            v-model="item.item_id"
                                                            @change="selectItem($event, item.item, true)"></v-autocomplete>
                                                    </template>
                                                </v-edit-dialog>
                                            </template>
                                            <template v-slot:[`item.qty_item`]="item">
                                                <v-edit-dialog>
                                                    {{ item.item.qty_item }}
                                                    <template v-slot:input>
                                                        <v-text-field @focus="$event.target.select()"
                                                            :disabled="item.item.delete" class="my-5"
                                                            :rules="rules.numberRule" label="بڕ بە دانە"
                                                            v-model="item.item.qty_item" type="number"></v-text-field>
                                                    </template>
                                                </v-edit-dialog>
                                            </template>
                                            <template v-slot:[`item.item_price`]="item">
                                                <v-edit-dialog>
                                                    {{ '$' + (sale.is_wholesale == 1 ? item.item.item_price_wholesale :
                                                        item.item.item_price) }}
                                                    <template v-slot:input>
                                                        <v-text-field @focus="$event.target.select()"
                                                            :disabled="item.item.delete" value="" :rules="rules.numberRule"
                                                            class="my-5" label=" نرخی فرۆشتن"
                                                            v-model="sale.is_wholesale == 1 ? item.item.item_price_wholesale : item.item.item_price"
                                                            type="number"></v-text-field>
                                                    </template>
                                                </v-edit-dialog>
                                            </template>
                                            <template v-slot:[`item.qty_unit`]="item">
                                                <v-edit-dialog>
                                                    {{ item.item.qty_unit }}
                                                    <template v-slot:input>
                                                        <v-text-field @focus="$event.target.select()"
                                                            :disabled="item.item.delete" :rules="rules.numberRule"
                                                            class="my-5" :label="'بڕ بە (' + item.item.unit_name + ')'"
                                                            v-model.number="item.item.qty_unit"
                                                            type="number"></v-text-field>
                                                    </template>
                                                </v-edit-dialog>
                                            </template>
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon">
                                                    <v-icon large class="ml-2" v-if="hover"
                                                        :color="item.delete ? 'white accent-2' : 'red accent-2'" id="icon"
                                                        @click="removeItemToggle(item)">{{ item.delete ?
                                                            'mdi-restore' : 'mdi-delete' }}</v-icon>
                                                    <v-icon large class="ml-2" v-else>{{ item.delete ?
                                                        'mdi-restore' : 'mdi-delete' }}</v-icon>
                                                </v-hover>
                                            </template>
                                        </v-data-table>
                                        <!-- <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-center">ناوی کاڵا</th>
                                                <th class="text-center">نرخی کڕین</th>
                                                <th class="text-center">نرخی فرۆشتن</th>
                                                <th class="text-center">دانە</th>
                                                <th class="text-center">سەرجەم</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <v-hover v-for="(purchase_item, i) in purchase.purchase_items" :key="i" v-slot="{ hover }" >
                                                <tr v-if="hover">
                                                    <td class="text-center mt-5"><v-autocomplete :items="itemReference" label="ناوی کاڵا" outlined dense item-text="item_name" return-object @change="selectItem($event, purchase_item, true)"></v-autocomplete></td>
                                                    <td class="text-center"><v-text-field @focus="$event.target.select()" v-model="purchase_item.cost_price" type="number" outlined dense></v-text-field></td>
                                                    <td class="text-center"><v-text-field @focus="$event.target.select()" v-model="purchase_item.item_price" type="number" outlined dense></v-text-field></td>
                                                    <td class="text-center"><v-text-field @focus="$event.target.select()" v-model="purchase_item.qty" type="number" outlined dense></v-text-field></td>
                                                    <td class="text-center">{{purchase_item.qty * purchase_item.cost_price}}</td>
                                                </tr>
                                                <tr v-else>
                                                    <td class="text-center">{{purchase_item.item_name}}</td>
                                                    <td class="text-center">{{purchase_item.cost_price}}</td>
                                                    <td class="text-center">{{purchase_item.item_price}}</td>
                                                    <td class="text-center">{{purchase_item.qty}}</td>
                                                    <td class="text-center">{{purchase_item.qty * purchase_item.cost_price}}</td>
                                                </tr>
                                                
                                            </v-hover>
                                            
                                                <td class="text-center"><v-autocomplete :items="itemReference" label="ناوی کاڵا" outlined dense item-text="item_name" return-object @change="selectItem($event, purchase_item)"></v-autocomplete></td>
                                            
                                        </tbody>
                                    </template>
                                </v-simple-table> -->
                                        <v-autocomplete class="mt-5" :items="itemReference" label="ناوی کاڵا" outlined dense
                                            item-text="item_name" item-value="item_id" v-model="sale_item" return-object
                                            :filter="filter" @change="selectItem($event, sale_item)"></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card>
                </v-dialog>
                <v-card>
                    <v-data-table class="elevation-2 rounded-md" :headers="headers" :items="sales" hide-default-footer
                        :items-per-page="20" :loading="loading">
                        <template v-slot:top>
                            <v-text-field @focus="$event.target.select()" v-model="table.search" label="گەڕان"
                                class="mx-4"></v-text-field>
                        </template>
                        <template v-slot:[`item.total`]="{ item }">
                            {{ '$' + Number(item.total.toFixed()).toLocaleString() }}
                        </template>
                        <template v-slot:[`item.payment_type`]="{ item }">
                            {{ item.payment_type == 'd' ? 'قەرز' : 'کاش' }}
                        </template>
                        <template v-slot:[`item.sale_type`]="{ item }">
                            {{ item.sale_type == 's' ? 'فرۆشتن' : 'گەڕانەوە' }}
                        </template>
                        <template v-slot:[`item.is_wholesale`]="{ item }">
                            {{ item.is_wholesale == true ? 'جوملە' : 'مفرد' }}
                        </template>

                        <template v-slot:[`item.actions`]="{ item }">
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="75" id="icon">
                                <v-icon large class="ml-2" v-if="hover" color="blue accent-2" id="icon"
                                    @click="prepairEdit(item.sale_id)">mdi-pencil</v-icon>
                                <v-icon large class="ml-2" v-else>mdi-pencil</v-icon>
                            </v-hover>
                            <v-hover v-slot="{ hover }" open-delay="75" close-delay="100" id="icon">
                                <v-icon large class="ml-2" v-if="hover" color="red accent-2" id="icon"
                                    @click="remove(item.sale_id)">mdi-delete</v-icon>
                                <v-icon large class="ml-2" v-else>mdi-delete</v-icon>
                            </v-hover>
                            <v-icon @click="$router.push({ name: 'PrintSale', params: { sale_id: item.sale_id } })" large
                                class="ml-2">mdi-eye</v-icon>
                        </template>
                    </v-data-table>
                </v-card>
                <v-pagination color="orange accent-2" :length="Math.ceil(table.numberOfSales / 20)"
                    v-model="table.page"></v-pagination>
            </v-col>
        </v-row>
        <notification />
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            table: {
                page: 1,
                numberOfSales: 0,
                search: ''
            },
            addLoading: false,
            items: [],
            dialog: false,
            menu1: false,
            customerReference: [],
            itemReference: [],
            item_id: 0,
            sale: {
                sale_id: 0,
                customer_id: 0,
                paid: 0,
                discount: 0,
                total: 0,
                date: new Date().toISOString().split('T')[0],
                note: '',
                is_wholesale: 0,
                sale_type: 's',
                no: 0,
                payment_type: 'd',
                sale_items: []
            },
            loading: false,
            sales: [],
            sale_item: {
                qty_item: 0,
                item_price: 0,
                item_name: '',
                unit_name: '',
                qty_unit: 0,
                item_id: 0,
            },
            headers: [
                { text: 'ژمارەی وەصل', value: 'sale_id', align: 'center' },
                { text: 'جۆری پارەدان', value: 'payment_type', align: 'center' },
                { text: 'ناوی کڕیار', value: 'customer_name', align: 'center' },
                { text: 'بەروار', value: 'date', align: 'center' },
                { text: 'داشکاندن', value: 'discount', align: 'center' },
                { text: 'جۆری فرۆشتن', value: 'sale_type', align: 'center' },
                { text: 'جوملە/مفرد', value: 'is_wholesale', align: 'center' },
                { text: 'کۆی گشتی', value: 'total', align: 'center' },
                { text: 'کردارەکان', value: 'actions', align: 'center' },
            ],
            secondHeaders: [
                { text: '#', value: 'index', align: 'center' },
                { text: 'ناوی کاڵا', value: 'item_name', align: 'center' },
                { text: 'کۆد', value: 'item_code', align: 'center' },
                { text: 'نرخی فرۆشتن', value: 'item_price', align: 'center' },
                { text: 'بڕ بە یەکە', value: 'qty_unit', align: 'center' },
                { text: 'بڕ بە دانە', value: 'qty_item', align: 'center' },
                { text: 'کۆی دانە', value: 'total_item', align: 'center' },
                { text: 'کۆی گشتی', value: 'total', align: 'center' },
                { text: 'کردارەکان', value: 'actions', align: 'center' },
            ],
            rules: {
                stringRule: [
                    (v) => {
                        return (v != '' || 'تکایە ئەم خانەیە پڕبکەرەوە')
                    }
                ],
                numberRule: [
                    (v) => {
                        return (v >= 0 || 'ژمارەکە پێویستە لە 0 زیاتربێت')
                    }
                ],
                customerRule: [
                    (v) => {
                        return (v > 0 || 'تکایە کڕیار دیاریبکە')
                    }
                ],

            }
        }
    },
    methods: {
        async getSales() {
            try {
                this.loading = true
                const { data } = await this.axios.get('/sale', { params: { page: this.table.page, search: this.table.search } })
                data.sales.forEach(sale => {
                    sale.date = new Date(sale.date).toISOString().split("T")[0]
                });
                this.sales = data.sales
                this.table.numberOfSales = data.count
                this.loading = false
            } catch (error) {
                this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
            }
        },
        async getCustomersReference() {
            try {
                const { data } = await this.axios.get('/customer/forReference')
                this.customerReference = data
            } catch (error) {
                this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
            }
        },
        async getItemsReference() {
            try {
                const { data } = await this.axios.get('/item/forReference')
                this.itemReference = data
            } catch (error) {
                this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
            }
        },

        async create() {
            this.addLoading = true
            if (this.$refs.sale.validate()) {
                if (this.sale.sale_items.length > 0) {
                    const sale = { ...this.sale }
                    sale.total = this.total
                    sale.sale_items.forEach(sale_item => {
                        sale_item.item_price = sale.is_wholesale == 1 ? sale_item.item_price_wholesale : sale_item.item_price
                        sale_item = this.removeProperty(sale_item, ['item_name', 'item_per_unit', 'unit_name', 'item_price_wholesale', 'item_code'])
                    })
                    try {
                        await this.axios.post('/sale', sale)
                        this.reset()
                        this.getSales()
                        this.dialog = false
                        this.emitter.$emit('notify', { type: 'success', text: 'ووەصڵەکە زیادکرا' })
                        this.addLoading = false
                    } catch (error) {
                        this.addLoading = false
                        console.log(error);
                        if (error.response.status == 422) {
                            return this.emitter.$emit('notify', { type: 'error', text: `تکایە هەموو بەشەکان بە تەواوی پڕبکەروە!` })
                        }
                        this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
                    }
                } else {
                    this.emitter.$emit('notify', { type: 'error', text: 'هیچ کاڵایەک زیاد نەکراوە' })
                }

            }
        },
        async update() {
            this.addLoading = true
            if (this.$refs.sale.validate()) {
                try {
                    const sale = { ...this.sale }
                    this.removeProperty(sale, ['customer_name', 'mobile', 'debt'])
                    sale.total = this.total
                    sale.sale_items.forEach(sale_item => {
                        sale_item.item_price = sale.is_wholesale == 1 ? sale_item.item_price_wholesale : sale_item.item_price
                        sale_item = this.removeProperty(sale_item, ['item_name', 'item_per_unit', 'unit_name', 'item_price_wholesale', 'item_code'])
                    })
                    await this.axios.patch('/sale/' + this.sale.sale_id, sale)
                    this.getSales()
                    this.reset()
                    this.dialog = false
                    this.emitter.$emit('notify', { type: 'success', text: 'وەصڵەکە نوێکرایەوە' })
                    this.addLoading = false
                } catch (error) {
                    this.addLoading = false
                    if (error.response.status == 422) {
                        return this.emitter.$emit('notify', { type: 'error', text: `${error.response.data.field} is duplicate` })
                    }
                    this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
                }
            }
        },
        remove(id) {
            this.$confirm('ئایا دڵنیاییت لە سڕینەوەی ئەم وەصڵە؟', 'دڵنیابوونەوە', 'question').then(async () => {
                try {
                    await this.axios.delete('/sale/' + id)
                    this.getSales()
                } catch (error) {
                    this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
                }
            })
        },
        reset() {
            this.sale = {
                sale_id: 0,
                customer_id: 0,
                paid: 0,
                discount: 0,
                total: 0,
                date: new Date().toISOString().split("T")[0],
                note: '',
                is_wholesale: false,
                sale_type: 's',
                no: 0,
                payment_type: 'd',
                sale_items: []
            }
            this.$refs.sale.resetValidation();
        },
        removeProperty(obj = {}, fields) {
            for (let i = 0; i < fields.length; i++)  delete obj[fields[i]]
        },
        async prepairEdit(id) {
            this.getCustomersReference()
            this.getItemsReference()
            try {
                const { data } = await this.axios.get('/sale/' + id)
                data.date = new Date(data.date).toISOString().split("T")[0]
                data.sale_items.forEach(sale_item => {
                    sale_item.delete = false
                    sale_item.item_price_wholesale = sale_item.item_price

                })
                this.sale = data
                this.sale.current_sale_type = this.sale.sale_type
                this.dialog = true
            } catch (error) {
                console.log(error);
                this.emitter.$emit('notify', { type: 'error', text: 'کێشەیەک ڕوویدا!' })
            }
        },
        async selectItem(val, obj, inList = false) {
            if (inList) {
                obj.item_name = val.item_name
                obj.item_id = val.item_id
                obj.cost_price = val.cost_price
                obj.item_price = val.item_price

            } else {
                const [s_item] = this.sale.sale_items.filter(sale_item => sale_item.item_id == val.item_id)

                if (!s_item) this.sale.sale_items.push({ ...val, qty_unit: 0, qty_item: 0, item_price_wholesale: obj.item_price_wholesale })

                this.sale_item = {}
            }
        },
        itemRowBackground: function (item) {
            return item.delete ? 'red accent-2' : ''
        },
        filter(item, queryText, itemText) {

            return item.item_code.includes(queryText) ||
                item.item_name.includes(queryText)
        },
        removeItemToggle(item) {
            if (item.sale_item_id) {
                if (item.delete) {
                    item.delete = false
                } else {
                    this.$confirm('ئایا دڵنیاییت لە سڕینەوەی ئەم کاڵایە؟', 'دڵنیابوونەوە', 'question').then(async () => {
                        item.delete = true
                    })
                }

            } else {
                this.sale.sale_items = this.sale.sale_items.filter(p_item => p_item.item_id != item.item_id)
            }
        },

    },

    created() {
        this.getSales()
    },
    watch: {
        dialog(val) {
            if (!val) {
                this.customerReference = []
                this.itemReference = []
                this.reset()
            }
        },
        'table.search': function () {
            this.getSales()
        },
        'table.page': function () {
            this.getSales()
        }
    },
    computed: {
        isUpdate() {
            return this.sale.sale_id
        },
        total() {
            let total = 0
            this.sale.sale_items.forEach(sale_item => total += (sale_item.qty_unit * Number(this.sale.is_wholesale == 1 ? sale_item.item_price_wholesale : sale_item.item_price) * sale_item.item_per_unit) + (sale_item.qty_item *
                Number(this.sale.is_wholesale == 1 ? sale_item.item_price_wholesale : sale_item.item_price)))
            return (total - this.sale.discount).toFixed(2)
        }
    }
}
</script>

<style>
#success {
    color: #4caf50 !important;
}

tr td {
    border-left: 0.1PX rgba(0, 0, 0, 0.38) solid;
}
</style>